import { Address, Agent, AgentDetails, BusinessAddress } from '@pv/dto'

export function agentDetailsMapper(agentDetails: AgentDetails): Agent {
  if (!agentDetails) return undefined
  return {
    displayName: agentDetails.nameOfAgency,
    servicingAssociateID: agentDetails.servicingAssociateID,
    agencyName: agentDetails.incorporatedName,
    address: buildAddress(agentDetails),
    emailAddress: agentDetails.businessEmailAddress,
    licenseNumber: buildLicenseNumber(agentDetails),
    pictureURL: agentDetails.associatePictureUrl,
    siteURL: agentDetails.micrositeUrl,
    phoneNumber: buildPhoneNumber(agentDetails),
    designations: agentDetails.designations
  }
}

const buildAddress = (agent): Address => {
  const businessAddress: BusinessAddress = agent.businessAddress
  if (businessAddress) {
    return {
      cityName: businessAddress.city,
      postalZipCode: businessAddress.zipCode.replace(/(\d{5})(\d{4})/, '$1-$2'),
      postalStateCode: businessAddress.stateProvince,
      streetAddressLine1Text: businessAddress.streetAddressLine1,
      streetAddressLine2Text: businessAddress.streetAddressLine2,
      countryNameText: businessAddress.county
    }
  } else {
    return undefined
  }
}

const buildLicenseNumber = agent => {
  const displayNumber = agent.licenseNumberDisplayInformation?.licenseToDisplayOnMainScreen
  return displayNumber && displayNumber.licenseNumberDisplayStateProvince && displayNumber.licenseNumberDisplayText
    ? displayNumber.licenseNumberDisplayStateProvince + '-' + displayNumber.licenseNumberDisplayText
    : undefined
}

const buildPhoneNumber = agent => {
  const phoneNumber = agent.displayPhoneNumber
  return phoneNumber ? phoneNumber.areaCode + '-' + phoneNumber.linePrefix + '-' + phoneNumber.lineSuffix : undefined
}
