const Constants = {
  options: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "Cache-Control": "no-cache"
    }
  },
  ACCESS_TOKEN_KEY: "sf-cauth1",
  REFRESH_TOKEN_KEY: "sf-cauth2",
  REFRESH_TOKEN_TYPE_HINT: "refresh_token",
  ACCESS_TOKEN_TYPE_HINT: "access_token",
  RefreshTokenExpiryCriteriaError: "Could not retrieve valid expiry for refresh token.",
  TokenNotFoundError: tokenName => `${tokenName === "sf-cauth1" ? "Access" : "Refresh"} token cookie was not set.`,
  TokenExchangeResponseError: "Token response is missing required properties.",
  RefreshTokenInvalidError: "Refresh token is invalid.",
  OktaSessionInvalidError: "Okta session is invalid.",
  RefreshTokenAndOktaSessionInvalidError: "Both refresh token and Okta session are invalid.",
  InvalidAccessJwtError: "Access token is not a valid JWT.",
  OktaSessionResponseError: "Could not retrieve valid Okta Session.",
  InvalidAccessConfig: "Invalid access token: unknown clientId, issuer, or audience",
  JWT_REGEX: /^[-\w]+?\.[-\w]+?\.([-\w]+)?$/,
  // sourced from node-jws (https://github.com/auth0/node-jws/blob/master/lib/verify-stream.js#L8), where it is used in the jsonwebtoken decode function (https://github.com/auth0/node-jsonwebtoken/blob/master/decode.js#L5):
  REFRESH_TOKEN_EXPIRY_CRITERIA: 900,
  configTable: {
    okta_test: [{
      iss: "https://auth.test.statefarm.com/oauth2/aus3w6pgj4rZqVJTT1d7",
      aud: "sf.b2c://api",
      cids: ["0oa4it3iefaKIoZW41d7", "0oa9tgn3opdpHj8sx1d7", "0oa8yepwryL4nq8l91d7", "0oa9th2ofgfvcQNNg1d7"]
    }],
    okta_prep: [{
      iss: "https://auth.prep.statefarm.com/oauth2/aus7ggc4eaDvI6Com1d7",
      aud: "sf.b2c://api",
      cids: ["0oa7fnk4a3x9K3gyk1d7", "0oa85vw4w9idlZJ8l1d7"]
    }],
    okta_prod: [{
      iss: "https://auth.statefarm.com/oauth2/aus2clrnwwWY9kBbc4h7",
      aud: "sf.b2c://api",
      cids: ["0oa2cupd0o46TfzBq4h7", "0oa5d4g6w1qw8II0w4h7", "0oa4pcvub7hZEnzGr4h7"]
    }]
  }
};
export default Constants;