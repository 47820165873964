import { DiscountDetails, Policy, DiscountsDetailBody } from '@pv/boat-dto'


export function discountsCardMapper(policy: Policy): DiscountDetails {
  const discountsDetailBody : any = [];
  const adjustments = policy.termVersion
  if(adjustments.hasOwnProperty("policyPricingRuleSet")){
    const displayName = adjustments.policyPricingRuleSet.pricingAdjustment
    for (var value of displayName){
    const item: DiscountsDetailBody={
      pricingRuleSetDisplayName: value.pricingRuleSetDisplayName
    }
    discountsDetailBody.push(item)
  }
  }
  return { discountsDetailBody};
}
