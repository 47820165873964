import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoggerType, PolicyViewResponse, ProductFlagEnum } from '@pv/dto'
import { policyViewResponseMapper } from '@pv/mapper'
import { catchError, lastValueFrom, map, of } from 'rxjs'
import { dataLayer } from '../constants/app.constants'
import { agent$, error$, logger$, policy$, productFlag$, pvt$, renewalDate$ } from '../store'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  policyRetrieve = (tdmToken: string) => {
    const url = tdmToken.startsWith('test') ? `/assets/data/${tdmToken}.json` : `policyRetrieve/${tdmToken}`
    return this.http.get(url).pipe(map(this.policyViewResponseHandler), catchError(this.policyViewErrorHandler))
  }

  policyManagement = (intent: string) => {
    const policyViewToken = pvt$()
    const headers = new HttpHeaders({ intent, policyViewToken })
    return lastValueFrom(this.http.get(`policyManagement`, { headers }))
  }

  logger = (data: LoggerType) => {
    return lastValueFrom(this.http.post('logger', data))
  }

  policyViewResponseHandler(response: PolicyViewResponse) {
    const { renewalDate, pvt, agent, productFlag } = policyViewResponseMapper(response)
    renewalDate && renewalDate$.set(renewalDate)
    pvt$.set(pvt)
    agent$.set(agent)

    if (response.productFlag === ProductFlagEnum.AUTO) {
      policy$.set('currentPolicy')
      productFlag$.set(productFlag)
      dataLayer.product = Object.keys(ProductFlagEnum)[Object.values(ProductFlagEnum).indexOf(productFlag$())]
      dataLayer.state = response.policyDetailsPCMM.currentPolicy.issuingStateName
    } else if (productFlag.includes('B')) {
      policy$.set('policy')
      productFlag$.set(productFlag)
      dataLayer.product = 'BOAT'
      dataLayer.state = response.policyDetailsAMM.policy.termVersion.insurableRisk[0].riskLocation.postalAddress.postalStateCode
    }

    if (!agent) {
      const errorMessage = 'Error while retrieving agency info'
      logger$.set(errorMessage)
    }
  }

  getViewDeclarationsForBoat = () => {
    const policyViewToken = pvt$()
    const docType = "DecPage"
    const headers = new HttpHeaders({ policyViewToken, docType })
     return lastValueFrom(this.http.get(`retrieveDocument`, { headers }))
    //return lastValueFrom(this.http.get('/assets/data/testDecPageResponse.json', { responseType: 'json' }))
  }

  getIdCardsForAuto = (physicalObject:string) => {
    const policyViewToken = pvt$()
    const docType = "AutoID"
    const headers = new HttpHeaders({ policyViewToken, docType, physicalObject })
    return lastValueFrom(this.http.get(`retrieveDocument`, { headers }))
  }



  policyViewErrorHandler(error: any) {
    error$.set(error)
    logger$.set(`${error}`)
    return of({})
  }
}
