import { Notification } from '@pv/dto'
import { Message } from '@pv/auto-dto'

export function messageToNotification(message: Message): Notification {
  return {
    type: message.customerMessageTypeName.toLowerCase(),
    message: message.customerMessageText
  }
}

export function toCurrency(amount: number, noDecimal = false): string {
  return amount?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: noDecimal ? 0 : 2,
    maximumFractionDigits: noDecimal ? 0 : 2
  })
}

export function getFutureDate(dateStr: string): string {
  const dt = new Date(dateStr)
  return dt.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' })
}

export function getFormattedRenewalDate(dateStr: string) {
  const dt = new Date(dateStr)
  return dt.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: '2-digit', timeZone: 'UTC' })
}

export function getRenewalDateForTab(dateStr: string) {
  const dt = new Date(dateStr)
  return dt.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', timeZone: 'UTC' })
}

export function getScreenId(type: string) {
  if (type.includes('current') || type.includes('policy')) return 'current-policy'
  else if (type.includes('renewal')) return 'policy-renewal'
  else if (type.includes('future')) return 'policy-change'
  else return ''
}
