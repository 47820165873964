import { BoatPolicyView, Policy } from '@pv/boat-dto'
import { context, wrapMapper } from '@pv/mapper'
import { get } from 'lodash'
import { getScreenId } from '../util'
import { additionalInterestDetailsMapper } from './additional-interest-mapper/additional-interest-mapper'
import { policyDetailsMapper } from './policy-details-mapper/policy-details-mapper'
import { watercraftDetailsMapper } from './watercraft-details-mapper/watercraft-details-mapper'
import { policyViewHeaderMapper } from './policy-view-header-mapper/policy-view-header-mapper'
import { policyCoverageDetailsMapper } from './policy-coverage-details/policy-coverage-details-mapper'
import { discountsCardMapper } from './discounts-mapper/discounts-mapper'

export function buildPolicyView(type: string): BoatPolicyView {
  if (context[type]) return context[type]

  const policy: Policy = get(context.amm, type)
  const additionalInterestDetails = wrapMapper(additionalInterestDetailsMapper,'Boat-additionalInterestDetailsMapper')(policy)
  const policyCoverageDetails = wrapMapper(policyCoverageDetailsMapper,'Boat-policyCoverageDetailsMapper')(policy)
  const discountDetails = wrapMapper(discountsCardMapper,'Boat-discountDetailsMapper')(policy)
  const policyDetails = wrapMapper(policyDetailsMapper,'Boat-policyDetailsMapper')(policy)
  // const policyDocuments = policyDocumentsMapper(policy)
  const watercraftDetails = wrapMapper(watercraftDetailsMapper,'Boat-watercraftDetailsMapper')(policy)

  const header = wrapMapper(policyViewHeaderMapper,'Boat-policyViewHeaderMapper')(policy)
  const showFraudDisclosure = policy.termVersion.insurableRisk[0].riskLocation.postalAddress.postalStateCode === 'CA'
  const policyView: BoatPolicyView = {
    additionalInterestDetails,
    discountDetails,
    header,
    policyCoverageDetails,
    policyDetails,
    // policyDocuments,
    showFraudDisclosure,
    watercraftDetails
  }
  policyView.metadata = {
    state: policy.policyMailingPostalAddress.postalStateCode,
    screenId: getScreenId(type)
  }
  context[type] = policyView

  return policyView
}
