import axios from "axios";
import Cookies from "js-cookie";
import Constants from "./Constants";
import getOktaAuthConfig from "./getOktaAuthConfig";
import { isRefreshTokenValid, isOktaSessionValid } from "./isValid";
const {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_TYPE_HINT,
  options,
  TokenExchangeResponseError,
  RefreshTokenInvalidError,
  OktaSessionInvalidError,
  RefreshTokenAndOktaSessionInvalidError
} = Constants;

// Exchanges a refresh token for a new set of Oauth2 access and refresh tokens
// Returns a boolean, representing whether the exchange was successful and the tokens were set as browser cookies
export const exchangeOauth2Tokens = async () => {
  try {
    const {
      clientId,
      issuer
    } = await getOktaAuthConfig();
    const refreshUrl = `${issuer}/v1/token`;
    const data = {
      refresh_token: Cookies.get(REFRESH_TOKEN_KEY),
      grant_type: REFRESH_TOKEN_TYPE_HINT,
      client_id: clientId
    };

    // This will need to be restored in future state
    //
    // const validRefreshToken = await isRefreshTokenValid(clientId, issuer);
    // const validOktaSession = await isOktaSessionValid(clientId, issuer);

    // if (!validRefreshToken && !validOktaSession) {
    //   throw new Error(RefreshTokenAndOktaSessionInvalidError);
    // } else if (!validRefreshToken || !validOktaSession) {
    //   throw new Error(!validRefreshToken ? RefreshTokenInvalidError : OktaSessionInvalidError);
    // }

    // Check if the refresh token and Okta session are valid before attempting to exchange
    const validRefreshToken = await isRefreshTokenValid(clientId, issuer);

    // Throw an error if refresh token or okta session are invalid
    if (!validRefreshToken) {
      throw new Error(RefreshTokenInvalidError);
    }

    // If reached, refresh token and Okta session are valid so call the refresh token endpoint
    const response = await axios.post(refreshUrl, data, options);
    const {
      access_token,
      refresh_token
    } = response && response.data ? response.data : {};
    // Set the access and refresh token in the browser cookies if they are defined
    if (access_token && refresh_token) {
      Cookies.set(ACCESS_TOKEN_KEY, access_token, {
        secure: true,
        domain: ".statefarm.com"
      });
      Cookies.set(REFRESH_TOKEN_KEY, refresh_token, {
        secure: true,
        domain: ".statefarm.com"
      });
      return true;
    } else {
      throw new Error(TokenExchangeResponseError);
    }
  } catch (err) {
    console.log(err.toString());
  }
  return false;
};