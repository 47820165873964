import Constants from "./Constants";
const {
  REFRESH_TOKEN_EXPIRY_CRITERIA,
  RefreshTokenExpiryCriteriaError
} = Constants;

// Refresh token cannot be used if it would expire sooner than the new access token
const refreshTokenMatchesExpiryCriteria = expiryEpochSeconds => {
  // Refresh token expiry must be a positive integer
  if (!Number.isInteger(expiryEpochSeconds) || expiryEpochSeconds < 0) {
    throw new Error(RefreshTokenExpiryCriteriaError);
  }

  // Seconds until refresh token expires
  const secondsLeft = expiryEpochSeconds - Math.floor(new Date().getTime() / 1000);
  return secondsLeft > REFRESH_TOKEN_EXPIRY_CRITERIA;
};
export default refreshTokenMatchesExpiryCriteria;