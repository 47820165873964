import { Policy } from '@pv/boat-dto'
import { WatercraftDetails, Trailer } from 'libs/dto/src/boat/watercraft-details'

export function watercraftDetailsMapper(policy: Policy): WatercraftDetails {
  const watercraft = policy.termVersion.insurableRisk[0].watercraft
  const modelYearNumber = watercraft.modelYearNumber
  const manufacturerName = watercraft.manufacturerName
  const lengthCount = watercraft.lengthCount
  const physicalObjectSerialNumber = watercraft.physicalObjectSerialNumber
  const motor = watercraft.motor.map(motor => {
    const motorItem: Trailer = {
      modelYearNumber: motor.modelYearNumber,
      manufacturerName: motor.manufacturerName,
      engineHorsepowerCount: motor.engineHorsepowerCount,
      physicalObjectSerialNumber: motor.physicalObjectSerialNumber
    }
    return motorItem
  })
  const trailer = watercraft.trailer
  const mailingAddress = policy.termVersion.insurableRisk[0].riskLocation.postalAddress

  return {
    modelYearNumber,
    manufacturerName,
    lengthCount,
    physicalObjectSerialNumber,
    motor,
    trailer,
    mailingAddress
  }
}
