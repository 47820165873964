import { PeopleAndPropertyDetails } from '@pv/auto-dto'
import { GenericCoverage, GenericCoverageGroup, GenericCoverageProperty, Policy } from '@pv/auto-dto'
import { peopleAndPropertyCoverageNames } from '../../constants'
import { toCurrency } from '../../util'

export function peopleAndPropertyDetailsMapper(policy: Policy): PeopleAndPropertyDetails {
  const policyTerm = policy.policyTerms[0]

  const genericCoverageGroups = policyTerm.policyGenericCoverageSet?.genericCoverageGroups

  if (!genericCoverageGroups) {
    return { totalPremium: toCurrency(0), coverages: {} }
  }

  const peopleAndPropertyCoverages = filterPeopleAndPropertyCoverages(genericCoverageGroups)
  const fullTermCoverageGroupPremiumAmount = peopleAndPropertyCoverages.reduce((a, b) => a + b.fullTermCoverageGroupPremiumAmount, 0)
  const totalPremium = toCurrency(fullTermCoverageGroupPremiumAmount)
  const coverages = getCoverageDetails(peopleAndPropertyCoverages)
  return { totalPremium, coverages }
}

function filterPeopleAndPropertyCoverages(genericCoverageGroups: GenericCoverageGroup[]) {
  return genericCoverageGroups.filter(g => peopleAndPropertyCoverageNames.includes(g.genericCoverages[0].coverageName))
}

function getCoverageDetails(peopleAndPropertyCoverages: GenericCoverageGroup[]): any {
  return peopleAndPropertyCoverages
    .map(c => c.genericCoverages)
    .flat()
    .reduce((a: any, b: GenericCoverage) => {
      a[b.coverageName] = b.genericCoveragePropertys?.reduce((c: any, d: GenericCoverageProperty) => {
        c[d.coveragePropertyTypeText] = toCurrency(d.coveragePropertyValueNumeric, true)
        return c
      }, {})
      return a
    }, {})
}
