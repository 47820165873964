import axios from "axios";

// Make an axios get request for the static content config table
const getStaticConfigTable = async () => {
  const hostUrl = window.location.host;
  const domain = hostUrl && (hostUrl.includes("localhost") || hostUrl.includes("test.statefarm.com")) ? "static1-env4.test.st8fm.com" : "static1.st8fm.com";
  const staticContentUrl = `https://${domain}/en_US/applications/CIMStaticContent/OktaConfig-prod/lib/okta-config.json`;
  try {
    const response = await axios.get(staticContentUrl);
    if (response && response.data) {
      return response.data;
    }
  } catch (err) {
    console.log(err.toString());
  }
  return undefined;
};
export default getStaticConfigTable;