import { Link } from '@pv/dto'
import { PolicyDetails } from '@pv/auto-dto'
import { Customer, InsurancePolicyPartyRole, Policy } from '@pv/auto-dto'
import { startCase } from 'lodash'
import { context } from '@pv/mapper'
import { getFormattedRenewalDate, toCurrency } from '../../util'

export function policyDetailsMapper(policy: Policy): PolicyDetails {
  const policyTerm = policy.policyTerms[0]
  const title = 'Auto policy'
  const policyNumber = policy.policyDisplayNumber
  const cars = policyTerm.insurableRisks.length
  const drivers = policyTerm.insurancePolicyPartyRoles.filter(r => r.roleNameText === 'Driver').length
  const policyDescription = `${cars} ${cars > 1 ? 'cars' : 'car'}, ${drivers} ${drivers > 1 ? 'drivers' : 'driver'}`
  const policyDurationTitle = `${context.isCurrentPolicy ? 'Current' : 'New'}${
    (policyTerm.policyTermDurationText !== 'other' && (policyTerm.policyTermDurationText !== 'Annual' ? ' 6-month' : ' 12-month')) ||
    ('')
  } premium`
  const premiumAmount =
    toCurrency(policyTerm.fullTermPolicyPremiumAmount) +
    `${policyTerm.policyTermDurationText !== 'other' ? `${'/' + getPolicyTermDuration(policyTerm.policyTermDurationText)}` : ''}`
  const policyTermDuration = getPolicyTermDuration(policyTerm.policyTermDurationText)
  const renewalDate = getFormattedRenewalDate(policyTerm.termExpirationDate)
  const mailingAddress = policy.policyPostalAddress.postalAddress
  const policyOwners = getPolicyOwners(policyTerm.insurancePolicyPartyRoles, policyTerm.customers)


   const footerLinks = [{ value: 'View ID cards',  isHref: true }]
  

  return {
    title,
    policyNumber,
    policyDurationTitle,
    policyDescription,
    cars,
    drivers,
    premiumAmount,
    policyTermDuration,
    renewalDate,
    mailingAddress,
    policyOwners,
    footerLinks
  }
}

export function getPolicyOwners(insurancePolicyPartyRoles: InsurancePolicyPartyRole[], customers: Customer[]): string[] {
  const namedInsured = insurancePolicyPartyRoles
    .filter(d => d.roleNameText === 'NamedInsured')
    .filter(d => d.encryptedClientIdentifier)
    .map(d => d.encryptedClientIdentifier)
  return customers
    .filter(c => namedInsured.includes(c.encryptedClientIdentifier))
    .map(c => {
      const firstname = startCase(c.partyName?.firstName?.toLowerCase())
      const lastname = startCase(c.partyName?.lastName?.toLowerCase())
      const age = c.customerSnapshots[0]?.ageNumber
     return `${firstname} ${lastname}${age ? `, ` + age : ''}`
    })
}

export function getPolicyTermDuration(policyTermDurationText: string) {
  switch (policyTermDurationText) {
    case 'Annual':
      return 'yr'
    case 'SemiAnnual':
      return '6mos'
    case 'other':
      return 'other'
  }
  return undefined
}
