import { DeclarationDocument, PolicyDocuments } from '@pv/auto-dto'
import { Policy } from '@pv/auto-dto'

export const POLICY_BOOKLET = [
  {
    state: 'AL',
    policyBookletLabel: 'View Full: 9801C - Alabama State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9801C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'AR',
    policyBookletLabel: 'View Full: 9804C - Arkansas State Farm Personal Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9804C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'IL',
    policyBookletLabel: 'View Full: 9813C - Illinois State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9813C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'IN',
    policyBookletLabel: 'View Full: 9814C - Indiana State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9814C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'MO',
    policyBookletLabel: 'View Full: 9825C - Missouri State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9825C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'NE',
    policyBookletLabel: 'View Full: 9827C - Nebraska State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9827C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'NM',
    policyBookletLabel: 'View Full: 9831C - New Mexico State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9831C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'OH',
    policyBookletLabel: 'View Full: 9835C - Ohio State Farm Personal Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9835C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'OK',
    policyBookletLabel: 'View Full: 9836C - Oklahoma State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9836C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'TN',
    policyBookletLabel: 'View Full: 9842C - Tennessee State Farm Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9842C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
  {
    state: 'WI',
    policyBookletLabel: 'View Full: 9849C - Wisconsin State Farm Personal Car Policy Booklet',
    policyBookletLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/9849C.pdf',
    formLink: 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/',
    formType: '.pdf'
  },
]

export function policyDocumentsMapper(policy: Policy): PolicyDocuments {
  const { communications, policyTerms, endorsementForms, issuingStateName } = policy
  //Declaration Page
  let declarationData
  const declarationDocumentData = communications?.find(c => c.document.documentClassificationName === 'DeclarationPage')
  if (declarationDocumentData && declarationDocumentData.document.documentURL) {
    declarationData = {
      documentName: 'View Declarations Page (PDF)',
      documentURL: declarationDocumentData.document.documentURL
    }
  }

  //Booklet
  const getPolicyBooklet = POLICY_BOOKLET.find(booklet => booklet.state === issuingStateName)
  const policyBookletData: DeclarationDocument = {
    documentName: getPolicyBooklet.policyBookletLabel,
    documentURL: getPolicyBooklet.policyBookletLink
  }

  //Policy Level Documents
  const formUrl = 'https://www.statefarm.com/content/dam/sf-library/en-us/pca-endorsement/auto/'
  const formType = '.pdf'
  const policySpecificDocuments = []
  endorsementForms?.forEach(endorsementForm => {
    const endorsementDoc = {
      documentName: `${endorsementForm.formNumber} - ${endorsementForm.formDescriptionText}`,
      documentURL: `${formUrl}${endorsementForm.formNumber}${formType}`
    }
    policySpecificDocuments.push(endorsementDoc)
  })
  policySpecificDocuments.sort((a, b) => {
    return a.documentName.localeCompare(b.documentName)
  })

  //Car Specific Documents
  const carDocuments = []

  policyTerms[0].insurableRisks.forEach(insurableRisk => {
    if (insurableRisk.insurableRiskVehicle?.vehicle?.endorsementForms?.length > 0) {
      const carName = `${insurableRisk.insurableRiskVehicle.vehicle.vehicleDescriptionText} (****${insurableRisk.insurableRiskVehicle.vehicle.vehicleIdentificationNumber.slice(
        -4
      )})`
      const carSpecificEndorsementsDocuments = []
      insurableRisk.insurableRiskVehicle.vehicle.endorsementForms.forEach(endorsement => {
        carSpecificEndorsementsDocuments.push({
          documentName: `${endorsement.formNumber} - ${endorsement.formDescriptionText}`,
          documentURL: `${formUrl}${endorsement.formNumber}${formType}`
        })
      })
      carSpecificEndorsementsDocuments.sort((a, b) => {
        return a.documentName.localeCompare(b.documentName)
      })
      carDocuments.push({
        carName: carName,
        carSpecificEndorsements: carSpecificEndorsementsDocuments
      })
    }
  })

  return {
    policyBookLetDocument: policyBookletData,
    declarationDocument: declarationData,
    policySpecificDocuments: policySpecificDocuments,
    carSpecificDocuments: carDocuments
  }
}
