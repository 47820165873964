export const peopleAndPropertyCoverageNames = ['BodilyInjury', 'PropertyDamage', 'UninsuredBodilyInjury', 'UnderinsuredBodilyInjury', 'MedicalPayments']
export const VEHICLE_COVERAGE_SORT_CONSTANTS = ['Comprehensive', 'Collision', 'UninsuredPropertyDamage', 'EmergencyRoadService', 'RentalAndTravelExpense']
export const ADDITIONAL_COVERAGE_SORT_CONSTANTS = ['DeathDismembermentAndLossOfSight', 'LossOfEarnings', 'TotalDisability']
export const BOAT_COVERAGE_SORT_CONSTANTS = [
  'Boat & Equipment',
  'All Motors',
  'Boat Trailer',
  'Emergency Service',
  'Wreck Removal',
  'Watercraft Liability',
  'Fuel & Other Spill Liability',
  'Watercraft Medical Payments',
  'Watercraft Deductible',
  'Personal Property Deductible',
  'Uninsured Watercraft'
]

export const PRODUCT_TYES = {"AUTO":"Auto","BOAT":"Boat"}
