// Retrieve information about the Okta Session from the Okta sessions/me endpoint
// Calling this function also refreshes the session
import Constants from "./Constants";
import { OktaAuth } from "@okta/okta-auth-js";
const {
  OktaSessionResponseError
} = Constants;
const getOktaSession = async (clientId, issuer) => {
  try {
    const oktaAuth = new OktaAuth({
      clientId,
      issuer
    });
    const res = await oktaAuth.session.get();
    if (res && res.status) {
      return res;
    } else {
      throw new Error(OktaSessionResponseError);
    }
  } catch (err) {
    console.log(err.toString());
  }
  return {
    status: "INACTIVE"
  };
};
export default getOktaSession;