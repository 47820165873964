import { computed, signal, WritableSignal } from '@angular/core'
import {  ProductFlagEnum } from '@pv/dto'

export const showLoader$ = signal(false)
export const authToken$ = signal(undefined)
export const error$ = signal(undefined)
export const refreshOkta$: WritableSignal<any> = signal(true, { equal: () => false })

export const agent$ = signal(undefined)
export const pvt$ = signal(undefined)
export const renewalDate$ = signal(undefined)
export const env$ = signal(undefined)
export const productFlag$ = signal(undefined)

export const isAuto$ = computed(() => productFlag$() === ProductFlagEnum.AUTO)
export const isBoat$ = computed(() => productFlag$()?.includes('B'))

export const policy$: WritableSignal<string> = signal(undefined)
export const futureDate$: WritableSignal<string> = signal(undefined)
export const policyView$: WritableSignal<any> = signal(undefined)
export const logger$: WritableSignal<string> = signal(undefined)

export const isFuturePolicy$ = computed(() => policy$()?.startsWith('future'))
export const isRenewalPolicy$ = computed(() => policy$()?.startsWith('renewal'))

export const callManagement$: WritableSignal<string> = signal(undefined)

//TODO: Revisit after deployment. Dynamic codes will help in identifying the issue
const known_reasons = ['missingTDM', 'policyRetrieve', 'policyManagement', 'unknownError']
export const errorCode$ = computed(() => {
  const reason = error$()
  let autocode =  reason === 'policyManagement' ? 2 : 1;
  let boatcode = 4;

  const code = productFlag$() === ProductFlagEnum.AUTO ? autocode : boatcode
  return 'PL-View-00' + code
})
