import { PolicyView, PolicyViewResponse, ProductFlagEnum } from '@pv/dto'
import { context } from '@pv/mapper'
import { agentDetailsMapper } from '../agent-details-mapper/agent-details-mapper'
import * as auto from '../auto'
import * as boat from '../boat'
import { getRenewalDateForTab } from '../util'

export function policyViewResponseMapper(response: PolicyViewResponse) {
  context.availableActions = response.availableActions
  context.agent = agentDetailsMapper(response.agentInfo?.current)
  context.pcmm = response.policyDetailsPCMM
  context.amm = response.policyDetailsAMM
  context.renewalDate = context.pcmm?.renewalPolicy && getRenewalDateForTab(context.pcmm.currentPolicy.policyTerms[0].termExpirationDate)
  context.pvt = response.policyViewToken
  context.productFlag = response.productFlag
  return context
}

export function buildPolicyView(policyType: string): PolicyView {

  if (context.productFlag === ProductFlagEnum.AUTO) {
    return auto.buildPolicyView(policyType)
  } else {
    return boat.buildPolicyView(policyType)
  }
}
