const checkTable = (aud, cid, iss, table) => {
  if (typeof table !== "object" || table === null) return false;
  for (const authzServers of Object.values(table)) {
    if (!Array.isArray(authzServers)) continue;
    for (const authzServer of authzServers) {
      if (!!authzServer && authzServer.hasOwnProperty("iss") && authzServer.iss === iss && authzServer.hasOwnProperty("aud") && authzServer.aud === aud && authzServer.hasOwnProperty("cids") && Array.isArray(authzServer.cids) && authzServer.cids.indexOf(cid) > -1) {
        return true;
      }
    }
  }
  return false;
};
export default checkTable;