import { PolicyCoverageDetails, Policy, CoverageDetails, WatercraftDetails } from '@pv/boat-dto'
import { toCurrency } from '../../util'

export const BOAT_COVERAGE_SUBTEXT = [
  { coverage: 'Boat & Boat Equipment', subtext: 'Per Occurrence' },
  { coverage: 'Motor', subtext: 'Per Occurrence' },
  { coverage: 'Boat Trailer', subtext: 'Per Occurrence' },
  { coverage: 'Emergency Services', subtext: 'Per Occurrence' },
  { coverage: 'Watercraft Liability', subtext: 'Per Occurrence' },
  { coverage: 'Fuel & Other Spill Liability', subtext: 'Per Occurrence' },
  { coverage: 'Watercraft Medical Payments', subtext: 'Per Person' },
  { coverage: 'Policy Deductible', subtext: 'Per Occurrence' },
  { coverage: 'Option F', subtext: 'Per Occurrence' },
  { coverage: 'Option F-Personal Property', subtext: 'Per Occurrence' },
  { coverage: 'Boat Personal Property', subtext: 'Per Occurrence' },
  { coverage: 'Tournament Fishing Fee Reimbursement', subtext: 'Per Occurrence' },
  { coverage: 'Uninsured and Underinsured Watercraft', subtext: 'Per Occurrence' },
  { coverage: 'Boat Rental Reimbursement', subtext: 'Per Occurrence' },
  { coverage: 'Uninsured Watercraft', subtext: 'Per Occurrence' }
]

export const POLICY_COVERAGE_NAMES = [
  { coverage: 'Boat & Boat Equipment', label: 'Boat & Equipment' },
  { coverage: 'Motor', label: 'All Motors' },
  { coverage: 'Boat Trailer', label: 'Boat Trailer' },
  { coverage: 'Emergency Services', label: 'Emergency Service' },
  { coverage: 'Wreck Removal', label: 'Wreck Removal' },
  { coverage: 'Watercraft Liability', label: 'Watercraft Liability' },
  { coverage: 'Fuel & Other Spill Liability', label: 'Fuel & Other Spill Liability' },
  { coverage: 'Watercraft Medical Payments', label: 'Watercraft Medical Payments' },
  { coverage: 'Policy Deductible', label: 'Watercraft deductible' },
  { coverage: 'Option F', label: 'Personal Property deductible' },
  { coverage: 'Option F-Personal Property', label: 'Personal Property deductible' },
  { coverage: 'Boat Personal Property', label: 'Personal Property deductible' },
  { coverage: 'Tournament Fishing Fee Reimbursement', label: 'Tournament Fishing Fee Reimbursement' },
  { coverage: 'Uninsured and Underinsured Watercraft', label: 'Uninsured and Underinsured Watercraft' },
  { coverage: 'Boat Rental Reimbursement', label: 'Boat Rental Reimbursement' },
  { coverage: 'Uninsured Watercraft', label: 'Uninsured Watercraft' }
]

export function policyCoverageDetailsMapper(policy: Policy): PolicyCoverageDetails {
  const coverageDetailsArray: CoverageDetails[] = getCoverages(policy)
  // Add check to remove multiple instaces of All Motors Coverage
  const coverageDetails: CoverageDetails[] = coverageDetailsArray.filter((item, index) => {
    if (item.coverageName === 'All Motors') {
      return index === coverageDetailsArray.findIndex(i => i.coverageName === 'All Motors')
    }
    return true
  })

  return { coverageDetails }
}
function getCoverages(policy: Policy): CoverageDetails[] {
  const coverages = policy.termVersion.policyCoverageSet.coverage

  return coverages.map(cov => {
    const coverageName = determineCoverageName(cov.name)
    let coveragePropValue = ''
    let coveragePropType = ''

    if (cov.coverageProperty && cov.coverageProperty.length > 0) {
      const deductibleProperty = cov.coverageProperty.find(prop => prop.type === 'DEDUCTIBLE')

      if (cov.name === 'Boat Personal Property' && deductibleProperty) {
        coveragePropValue = convertCodeToCurrency(deductibleProperty.valueNum.toString())
      } else if (cov.coverageProperty[0].valueNum) {
        const valueNums = cov.coverageProperty.map(prop => prop.valueNum)
        coveragePropValue = convertCodeToCurrency(valueNums.join('/'))
      } else {
        coveragePropValue = cov.coverageProperty[0].valueText
      }

      if (cov.name !== 'Wreck Removal') {
        coveragePropType = determineSubText(cov.name)
      }
    }

    return { coverageName, coveragePropValue, coveragePropType }
  })
}

export function determineCoverageName(codedText: string): string {
  const wording = POLICY_COVERAGE_NAMES.find(label => label.coverage === codedText)
  if (!wording) {
    throw `Missed Coverage in boat POLICY_COVERAGE_NAMES-${codedText}`
  }
  return wording.label
}

function convertCodeToCurrency(codedCurrency: string): string {
  const codes = codedCurrency.split('/')
  return codes.map(code => transformCurrency(Number(code))).join(' / ')
}

function transformCurrency(amount: number) {
  return toCurrency(amount, true)
}

function determineSubText(codedText: string): string {
  const wording = BOAT_COVERAGE_SUBTEXT.find(subtext => subtext.coverage === codedText)
  return wording.subtext
}
