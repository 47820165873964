import introspectOauth2Token from "./introspectToken";
import refreshTokenMatchesExpiryCriteria from "./refreshTokenMatchesExpiryCriteria";
import getOktaSession from "./getOktaSession";
import Constants from "./Constants";
const {
  REFRESH_TOKEN_KEY,
  REFRESH_TOKEN_TYPE_HINT
} = Constants;

// Check if refresh token is active and will not expire before the next access token would
export const isRefreshTokenValid = async (clientId, issuer) => {
  try {
    const response = await introspectOauth2Token(REFRESH_TOKEN_KEY, REFRESH_TOKEN_TYPE_HINT, clientId, issuer);
    return !!(response && response.active && response.exp && refreshTokenMatchesExpiryCriteria(response.exp));
  } catch (err) {
    console.log(err.toString());
  }
  return false;
};

// Check if the Okta session is ACTIVE
export const isOktaSessionValid = async (clientId, issuer) => {
  try {
    const response = await getOktaSession(clientId, issuer);
    return !!(response && response.status === "ACTIVE");
  } catch (err) {
    console.log(err.toString());
  }
  return false;
};