import { Notification, PolicyViewHeader } from '@pv/dto'
import { isEmpty } from 'lodash'
import { Policy } from '@pv/auto-dto'
import { context } from '@pv/mapper'
import { getFutureDate } from '../../util'

export function policyViewHeaderMapper(policy: Policy): PolicyViewHeader {
  const policyTerm = policy.policyTerms[0]
  const title = context.isFuturePolicy ? 'Review your policy change' : 'My policy details'
  const canChange = context.availableActions.map(a => a.action).find(a => a === 'ChangeCoverage' || a === 'ChangeLeaseFinanceInfo')
  const exclusions: Notification[] =
    policyTerm.messages &&
    policyTerm.messages
      .filter(m => m.customerMessageEntityRelationshipName === undefined)
      .map(m => ({
        type: m.customerMessageTypeName.toLowerCase(),
        message: m.customerMessageText
      }))
  const policyViewHeader: PolicyViewHeader = { title, showMakeChange: canChange?.length > 0, notifications: exclusions }
  const dates = context.isFuturePolicy ? [] : context.pcmm.futureEffectiveDates?.map(getFutureDate)
  if (!isEmpty(dates)) {
    const message = dates.length > 1 ? 'Changes will occur on:' : `Your change will take effect on ${dates[0]}.`
    const isBold = dates.length > 1
    const notification: Notification = { type: 'informational', message, isBold }
    policyViewHeader.futureConfig = { notification, dates }
  }
  return policyViewHeader
}
