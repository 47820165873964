export interface LoggerType {
    logLevel: string
    logMessage: string
    lob: string
  }

export enum LoggerLevel {
    INFO = 'info',
    WARN = 'warn',
    TRACE = 'trace',
    DEBUG = 'debug',
    ERROR = 'error'
}
  
  