export * from './policy-view-response-mapper/policy-view-response-mapper'

export const context: any = {}

export const wrapMapper = (mapper: Function, mapperName: string) => {
   return (...args: any[]) => {
    try {
        return mapper(...args)
    } catch(error) {
        error.mapperName = mapperName;
        throw error;
    }
   }
}
