import { parseJwt } from "./parseJwt.js";
import checkTable from "./checkTable.js";
import getStaticConfigTable from "./getStaticConfigTable.js";
import Constants from "./Constants";
const {
  InvalidAccessConfig,
  configTable
} = Constants;

// Returns clientId and issuer to configure the OktaAuth instance.
// First, read the access Jwt to get clientId, issuer, and audience.
// Then, if the local config table doesn't have an entry for those
// values it fetches a backup table hosted as static content.
// If neither table matches the aud, cid, and iss throw an error.
const getOktaAuthConfig = async () => {
  const {
    aud,
    cid,
    iss
  } = parseJwt();
  const config = {
    clientId: cid,
    issuer: iss
  };
  const staticConfigTable = await getStaticConfigTable();
  if (staticConfigTable && checkTable(aud, cid, iss, staticConfigTable)) return config;
  if (checkTable(aud, cid, iss, configTable)) return config;
  throw new Error(InvalidAccessConfig);
};
export default getOktaAuthConfig;