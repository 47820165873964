import { AdditionalCoverages } from '@pv/auto-dto'
import { Customer, GenericCoverageGroup, GenericCoveragePartyRole, Policy } from '@pv/auto-dto'
import { isEmpty, startCase } from 'lodash'
import { toCurrency } from '../../util'
import { peopleAndPropertyCoverageNames } from '../../constants'
import { ADDITIONAL_COVERAGE_SORT_CONSTANTS } from '../../constants'

export const ADDITIONAL_COVERAGE_NAMES = [
  { coverage: 'DeathDismembermentAndLossOfSight', label: 'Death, Dismemberment and Loss of Sight' },
  { coverage: 'LossOfEarnings', label: 'Loss of Earnings' },
  { coverage: 'TotalDisability', label: 'Total Disability' }
]

export function additionalCoverageMapper(policy: Policy): AdditionalCoverages {
  const policyTerm = policy.policyTerms[0]
  const genericCoverageGroups = policyTerm.policyGenericCoverageSet.genericCoverageGroups
  const additionalCoverageGroups = genericCoverageGroups.filter(g => ADDITIONAL_COVERAGE_SORT_CONSTANTS.includes(g.genericCoverages[0].coverageName))
  const coveragePremiumAmount = additionalCoverageGroups.reduce((a, b) => a + b.fullTermCoverageGroupPremiumAmount, 0)
  const premiumAmount = toCurrency(coveragePremiumAmount)
  const isArkansasState = policy.issuingStateName === "AR"
  const coverages = getAdditionalCoverages(additionalCoverageGroups, policyTerm.customers,isArkansasState )
  return isEmpty(coverages) ? { premiumAmount:toCurrency(0), coverages:{}, isArkansasState: false } : { premiumAmount, coverages, isArkansasState }
}

export function getAdditionalCoverages(additionalCoverageGroups: GenericCoverageGroup[], customers: Customer[],isArkansasState:boolean) {

  additionalCoverageGroups.sort(
    (a, b) => ADDITIONAL_COVERAGE_SORT_CONSTANTS.indexOf(a.genericCoverages[0].coverageName) - ADDITIONAL_COVERAGE_SORT_CONSTANTS.indexOf(b.genericCoverages[0].coverageName)
  )

  return additionalCoverageGroups.map(gcg => {
    const genericCoverage = gcg.genericCoverages[0]
    const coverageName = determineCoverageName(genericCoverage.coverageName)
    let customerNames = []
    let coveragePropValue = ''
    let coveragePropType = ''

    
    if (['LossOfEarnings', 'TotalDisability'].includes(genericCoverage.coverageName)) {
      coveragePropValue = 'Included'
      if(!isArkansasState){
          customerNames = getCoveragePartyRoles(genericCoverage.genericCoveragePartyRoles, customers)
        }
      }
    else if (genericCoverage.genericCoveragePropertys && genericCoverage.genericCoveragePropertys.length > 0) {
        const codedValue = genericCoverage.genericCoveragePropertys.filter(m => m.coveragePropertyTypeText === 'LimitPerPerson').map(prop => prop.coveragePropertyValueNumeric)
        coveragePropValue = transformCurrency(Number(codedValue))
        coveragePropType = isArkansasState ? 'Limit' : 'Per person'
        if(!isArkansasState){
          customerNames = getCoveragePartyRoles(genericCoverage.genericCoveragePartyRoles, customers)
        }
      }
    return { coverageName, coveragePropValue, coveragePropType, customerNames }
    
  })
}

function getCoveragePartyRoles(genericCoveragePartyRoles: GenericCoveragePartyRole[], customers: Customer[]) {
  const ids = genericCoveragePartyRoles?.map(g => g.encryptedClientIdentifier)
  return ids
    .map(id => {
      const customer = customers?.find(c => c.encryptedClientIdentifier === id)
      const firstname = startCase(customer.partyName.firstName.toLowerCase())
      const lastname = startCase(customer.partyName.lastName.toLowerCase())
      const age = customer.customerSnapshots[0]?.ageNumber
       return `${firstname} ${lastname}${age ? `, `+ age: "" }`
    })
    .sort()
}

export function determineCoverageName(codedText: string): string {
  const wording = ADDITIONAL_COVERAGE_NAMES.find(label => label.coverage === codedText)  
  if (!wording) {
    throw `Missed Coverage in Auto ADDITIONAL_COVERAGE_NAMES-${codedText}`
  }
  return wording.label
  
}
function transformCurrency(amount: number) {
  return toCurrency(amount, true)
}