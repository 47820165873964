import axios from "axios";
import Cookies from "js-cookie";
import Constants from "./Constants";
const {
  options,
  TokenNotFoundError
} = Constants;

// Retrieve Oauth2 Token information from Okta introspect endpoint
const introspectOauth2Token = async (tokenCookieKey, token_type_hint, clientId, issuer) => {
  try {
    const introspectUrl = `${issuer}/v1/introspect`;
    const tokenValue = Cookies.get(tokenCookieKey);
    const data = {
      token: tokenValue,
      token_type_hint: token_type_hint,
      client_id: clientId
    };
    if (tokenValue) {
      const response = await axios.post(introspectUrl, data, options);
      if (response && response.data) return response.data;
    } else {
      throw new Error(TokenNotFoundError(tokenCookieKey));
    }
  } catch (err) {
    console.log(err.toString());
  }
  return {
    active: false
  };
};
export default introspectOauth2Token;