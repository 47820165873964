import { effect, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { LoggerLevel, ProductFlagEnum } from '@pv/dto'
import { buildPolicyView } from '@pv/mapper'
import { exchangeOauth2Tokens } from 'customer-oauth2-token-management-utility'
import { CookieService } from 'ngx-cookie-service'
import { dataLayer } from '../../constants/app.constants'
import { ApiService } from '../../services/api.service'
import { callManagement$, error$, isAuto$, logger$, policy$, policyView$, productFlag$, refreshOkta$ } from '../signals'

//TODO: Split into multiple effects
@Injectable({ providedIn: 'root' })
export class AppEffects {
  private policyChangeEffect = effect(
    () => {
      const policy = policy$()
      if (policy) {
        const pv = buildPolicyView(policy)
        policyView$.set(pv)
        dataLayer.screenId = pv.metadata.screenId
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    { allowSignalWrites: true }
  )

  private gotoErrorEffect = effect(
    () => {
      const gotoError = error$()
      if (gotoError) {
        dataLayer.screenId = 'error'
      }
    },
    { allowSignalWrites: true }
  )

  private refreshOktaEffect = effect(async () => {
    if (refreshOkta$()) {
      if (this.cookieService.check('sf-cauth2')) {
        try {
          await exchangeOauth2Tokens()
        } catch (e) {
          logger$.set(`Okta Token refresh Failed + ':' + ${JSON.stringify(e)}`)
        }
      }
    }
  })

  private callManagementEffect = effect(
    () => {
      const intent = callManagement$()
      if (intent) {
        refreshOkta$.set('callManagement')
        this.apiService.policyManagement(intent).then((response: any) => (location.href = response.redirectUrl))
      }
    },
    { allowSignalWrites: true }
  )

  private logEvent = effect(
    () => {
      const message = logger$()
      if (message) {
        const logMessage = {
          logLevel: LoggerLevel.ERROR,
          logMessage: message,
          lob: isAuto$() ? 'AUTO' : 'BOAT'
        }
        this.apiService.logger(logMessage).catch(console.error)
      }
    },
    { allowSignalWrites: true }
  )

  constructor(private router: Router, private cookieService: CookieService, private apiService: ApiService) {}
}
