import { Link } from '@pv/dto'
import { PolicyDetails, Policy } from '@pv/boat-dto'
import { getFormattedRenewalDate, toCurrency } from '../../util'

export function policyDetailsMapper(policy: Policy): PolicyDetails {
  const title = 'Boat policy'
  const policyNumber = policy.agreementDisplayNumber
  const premiumAmount = `${toCurrency(policy.termVersion.totalPolicyPremiumAmount)}/yr`
  const renewalDate = `${getFormattedRenewalDate(policy.termVersion.fullTermEndDate)}`
  const mailingAddress = policy.policyMailingPostalAddress
  const policyOwners = policy.policyPartyRole.filter(pr => pr.party?.partyName?.fullName).map(pr => pr.party.partyName.fullName)
  let footerLinks: Link[] = [{ value: 'View Declarations', key: 'ViewDeclarationsForBoat', isViewDeclarationsforBoat: true }]

  return {
    title,
    policyNumber,
    premiumAmount,
    renewalDate,
    mailingAddress,
    policyOwners,
    footerLinks
  }
}
