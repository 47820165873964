import { AutoPolicyView, Policy } from '@pv/auto-dto'
import { context, wrapMapper } from '@pv/mapper'
import { get } from 'lodash'
import { getScreenId } from '../util'
import { additionalCoverageMapper } from './additional-coverage-mapper/additional-coverage-mapper'
import { driverDetailsMapper } from './driver-details-mapper/driver-details-mapper'
import { peopleAndPropertyDetailsMapper } from './people-and-property-details-mapper/people-and-property-details-mapper'
import { policyDetailsMapper } from './policy-details-mapper/policy-details-mapper'
import { policyDocumentsMapper } from './policy-documents-mapper/policy-documents-mapper'
import { policyViewHeaderMapper } from './policy-view-header-mapper/policy-view-header-mapper'
import { vehicleDetailsMapper } from './vehicle-details-mapper/vehicle-details-mapper'

export function buildPolicyView(type: string): AutoPolicyView {
  if (context[type]) return context[type]

  context.isFuturePolicy = type.startsWith('future')
  context.isRenewalPolicy = type.startsWith('renewal')
  context.isCurrentPolicy = type.startsWith('current')

  const policy: Policy = get(context.pcmm, type)
  const header = wrapMapper(policyViewHeaderMapper,'Auto-policyViewHeaderMapper')(policy)
  const policyDetails = wrapMapper(policyDetailsMapper,'Auto-policyDetailsMapper')(policy)
  const vehicleDetails = wrapMapper(vehicleDetailsMapper,'Auto-vehicleDetailsMapper')(policy)
  const driverDetails = wrapMapper(driverDetailsMapper,'Auto-driverDetailsMapper')(policy)
  const peopleAndPropertyDetails = wrapMapper(peopleAndPropertyDetailsMapper,'Auto-peopleAndPropertyDetailsMapper')(policy)
  const additionalCoverages = wrapMapper(additionalCoverageMapper,'Auto-additionalCoverageMapper')(policy)
  const policyDocuments = wrapMapper(policyDocumentsMapper,'Auto-policyDocumentsMapper')(policy)
  
  const policyView: AutoPolicyView = {
    header,
    policyDetails,
    vehicleDetails,
    driverDetails,
    peopleAndPropertyDetails,
    additionalCoverages,
    policyDocuments
  }
  policyView.metadata = {
    state: policy.issuingStateName,
    screenId: getScreenId(type)
  }

  context[type] = policyView

  return policyView
}
