import { Notification } from '@pv/dto'
import { DriverCourse, DriverDetail, DriverDetails } from '@pv/auto-dto'
import { Customer, CustomerEducation, InsurancePolicyPartyRole, Message, Policy } from '@pv/auto-dto'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { context } from '@pv/mapper'
import { messageToNotification } from '../../util'

export function driverDetailsMapper(policy: Policy): DriverDetails {
  const policyTerm = policy.policyTerms[0]
  const messages = policyTerm.messages
  const notifications: Notification[] = getNotifications(messages)
  const details = getDriverDetails(policyTerm.insurancePolicyPartyRoles, policyTerm.customers, policyTerm.termEffectiveDate)
  const footerLinks = context.availableActions
    .filter(aa => aa.action === 'AddDriver')
    .map(() => ({
      key: 'AddDriver',
      value: 'Add a driver'
    }))
  return {
    notifications,
    details,
    footerLinks
  }
}

function getNotifications(messages: Message[]): Notification[] {
  return messages?.filter(m => m.customerMessageEntityRelationshipName === 'drivers_party_role').map(messageToNotification)
}

function getDriverDetails(insurancePolicyPartyRoles: InsurancePolicyPartyRole[], customers: Customer[], termEffectiveDate?: string): DriverDetail[] {
  const partyRoles = insurancePolicyPartyRoles.filter(d => d.roleNameText === 'Driver')
  const clientIds = partyRoles.map(p => p.encryptedClientIdentifier)
  return customers
    .filter(c => clientIds.includes(c.encryptedClientIdentifier))
    .map(c => {
      const partyRole = partyRoles.find(r => r.encryptedClientIdentifier === c.encryptedClientIdentifier)
      const firstname = startCase(c.partyName.firstName.toLowerCase())
      const lastname = startCase(c.partyName.lastName.toLowerCase())
      const age = c?.customerSnapshots[0]?.ageNumber
      const name_age = `${firstname} ${lastname}${age ? `, ` + age : ``}`
      const excluded = partyRole.excludedDriverIndicator === 'Y'
      const courses = excluded ? [] : getCourses(c, termEffectiveDate)
      return { name_age, excluded, courses }
    })
}

function getCourses(customer: Customer, termEffectiveDate: string): DriverCourse[] {
  const courses = []
  if (customer.customerSnapshots[0]?.defensiveDrivingCourseEligibleSwitch) {
    const name = 'Defensive driving course discount'
    const status = getCourseStatus('DefensiveDrivingCourse', customer.customerEducations, termEffectiveDate)
    courses.push({ name, status })
  }
  if (customer.customerSnapshots[0]?.steerClearIndicator) {
    const name = 'Steer Clear® safe driver program'
    const status = getCourseStatus('SteerClearCourse', customer.customerEducations, termEffectiveDate)
    courses.push({ name, status })
  }
  if(customer.customerSnapshots[0]?.collegeGradEligibleSwitch){
    const name = 'College graduate discount'
    const status = 'Enrolled'
    courses.push({name, status})
  }
  return courses
}

function getCourseStatus(name: string, customerEducations: CustomerEducation[], termEffectiveDate: string) {
  const education = customerEducations.find(e => e.educationCourseName === name)
  let status = 'Eligible'
  if (education?.educationCourseCompletionDate) {
    const ccd = dayjs(education.educationCourseCompletionDate)
    const ted = dayjs(termEffectiveDate)
    status = ted.diff(ccd, 'years', true) <= 3 ? 'Complete' : status
  }
  return status
}
export let testNonExportedFunctions = {
  getCourseStatus
}
