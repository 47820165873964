import { Policy, additionalInterestDetails, additionalInterestBody } from '@pv/boat-dto'
export function additionalInterestDetailsMapper(policy: Policy): additionalInterestDetails {
  const additionalInterestBody: any = []
  const varWatercraft = policy.termVersion.insurableRisk[0].watercraft
  if (varWatercraft.hasOwnProperty('physicalObjectPartyRole')) {
    const physicalObjectPartyRole = varWatercraft.physicalObjectPartyRole
    for (var physicalObject of physicalObjectPartyRole) {
      const item: additionalInterestBody = {
        fullName: physicalObject.party.partyName.fullName,
        address: physicalObject.party.postalAddress
      }
      additionalInterestBody.push(item)
    }
  }
  return { additionalInterestBody }
}
