import Cookies from "js-cookie";
import Constants from "./Constants";
const {
  ACCESS_TOKEN_KEY,
  InvalidAccessJwtError,
  TokenNotFoundError,
  JWT_REGEX
} = Constants;

// Parses a JWT token and returns it as a JSON object
export const parseJwt = () => {
  try {
    const accessTokenJwt = Cookies.get(ACCESS_TOKEN_KEY);
    if (!accessTokenJwt) throw new Error(TokenNotFoundError(ACCESS_TOKEN_KEY));
    const validJwt = JWT_REGEX.test(accessTokenJwt);
    if (validJwt) {
      let jwtPayload = accessTokenJwt.split(".")[1];
      let jwtUrlDecoded = jwtPayload.replace(/-/g, "+").replace(/_/g, "/");
      let jwtBase64Decoded = atob(jwtUrlDecoded);
      let jwtPayloadJSON = JSON.parse(jwtBase64Decoded);
      return jwtPayloadJSON;
    } else {
      throw new Error(InvalidAccessJwtError);
    }
  } catch (err) {
    console.log(err.toString());
  }
  return {};
};