import { AvailableAction, Link, Notification } from '@pv/dto'
import { CoverageDetails, VehicleDetail, VehicleDetails } from '@pv/auto-dto'
import { GenericCoverageGroup, InsurableRisk, Message, Policy, PolicyGenericCoverageSet, VehiclePartyRole } from '@pv/auto-dto'
import { startCase } from 'lodash'
import { context } from '@pv/mapper'
import { VEHICLE_COVERAGE_SORT_CONSTANTS } from '../../constants'
import { messageToNotification, toCurrency } from '../../util'
import { getPolicyTermDuration } from '../policy-details-mapper/policy-details-mapper'

export const VEHICLE_COVERAGE_SUBTEXT = [
  { coverage: 'Comprehensive', subtext: 'Deductible per accident' },
  { coverage: 'Collision', subtext: 'Deductible per accident' },
  { coverage: 'UninsuredPropertyDamage', subtext: 'Per accident / deductible' }
]

export const VEHICLE_COVERAGE_NAMES = [
  { coverage: 'Comprehensive', label: 'Comprehensive' },
  { coverage: 'Collision', label: 'Collision' },
  { coverage: 'UninsuredPropertyDamage', label: 'Uninsured Motor Vehicle - Property Damage' },
  { coverage: 'RentalAndTravelExpense', label: 'Car Rental and Travel Expenses' },
  { coverage: 'EmergencyRoadService', label: 'Emergency Road Service' },
  { coverage: 'BodilyInjury', label: 'Liability - Bodily Injury' },
  { coverage: 'PropertyDamage', label: 'Liability - Property Damage' },
  { coverage: 'BodilyInjuryPropertyDamage', label: 'Liability - Bodily Injury and Property Damage' },
  { coverage: 'PropertyDamageBodilyInjury', label: 'Liability - Bodily Injury and Property Damage' },
  { coverage: 'UninsuredBodilyInjury', label: 'Uninsured Motor Vehicle - Bodily Injury' },
  { coverage: 'UnderinsuredBodilyInjury', label: 'Underinsured Motor Vehicle - Bodily Injury' },
  { coverage: 'MedicalPayments', label: 'Medical Payments' }
]

export function vehicleDetailsMapper(policy: Policy): VehicleDetails {
  const menuLinks = buildVehicleDetailMenu(context.availableActions)
  const details: VehicleDetail[] = buildVehicleDetails(policy)
  const footerLinks = buildVehicleDetailFooter(context.availableActions)
  return { menuLinks, details, footerLinks }
}

function buildVehicleDetailMenu(availableActions: AvailableAction[]): Link[] {
  const links: Link[] = []
  availableActions.forEach(aa => {
    if (aa.action === 'ChangeLeaseFinanceInfo')
      links.push({
        key: 'ChangeLeaseFinanceInfo',
        value: 'Update your lease or financial info'
      })
    if (aa.action === 'ChangeAddress') links.push({ key: 'ChangeAddress', value: 'Update your garage address' })
  })
  return links.sort((a, b) => b.key.localeCompare(a.key))
}

function buildVehicleDetailFooter(availableActions: AvailableAction[]): Link[] {
  const links: Link[] = []
  availableActions.forEach(aa => {
    if (aa.action === 'AddVehicle') links.push({ key: 'AddVehicle', value: 'Add a car' })
    if (aa.action === 'ReplaceVehicle') links.push({ key: 'ReplaceVehicle', value: 'Replace a car' })
  })
  return links.sort((a, b) => a.key.localeCompare(b.key))
}

function buildVehicleDetails(policy: Policy): VehicleDetail[] {
  const insurableRisks = policy.policyTerms[0].insurableRisks
  return insurableRisks.map(ir => getVehicleDetails(ir, policy))
}

function getVehicleDetails(ir: InsurableRisk, policy: Policy): VehicleDetail {
  const vehicle = ir.insurableRiskVehicle.vehicle
  const status = getVehicleStatus(vehicle.vehiclePartyRoles)
  const coverages = getVehicleCoverages(ir.riskGenericCoverageSet.genericCoverageGroups)

  let notifications = []
  if (ir.messages) {
    notifications = getNotifications(ir.messages)
  }

  let coverageExclusions: string[] = []

  if (ir.policyVehicleSuspendedSwitch) {
    coverageExclusions = getExcludedCoverages(policy.policyTerms[0].policyGenericCoverageSet)
  }

  return {
    year: vehicle.modelYearNumber,
    physicalObject:vehicle.physicalObjectIdentifier,
    make: startCase(vehicle.makeName.toLowerCase()),
    model: vehicle.modelName.length > 3 ? startCase(vehicle.modelName.toLowerCase()) : vehicle.modelName,
    description: vehicle.bodyStyleDescriptionText,
    vin: vehicle.vehicleIdentificationNumber,
    garageLocation: ir.insurableRiskLocation.geographicLocation.postalAddress,
    status,
    premiumText: getFormattedPremiumText(ir.fullTermVehiclePremiumTotal, policy.policyTerms[0].policyTermDurationText),
    driveSafeAndSaveEnrollmentSwitch: ir.insurableRiskVehicle.driveSafeAndSaveEnrollmentSwitch,
    antiTheftSwitch: vehicle.antiTheftSwitch,
    policyVehicleSuspendedSwitch: ir.policyVehicleSuspendedSwitch,
    excludeCoverages: coverageExclusions,
    coverages,
    notifications,
    showTnc: vehicle.endorsementForms?.some(endorsement => endorsement.formDescriptionText.toLowerCase().includes('transportation network company'))
  }
}

function getVehicleCoverages(genericCoverageGroups: GenericCoverageGroup[]): CoverageDetails[] {
  // need to sort the coverages so they match Figma
  genericCoverageGroups.sort(
    (a, b) => VEHICLE_COVERAGE_SORT_CONSTANTS.indexOf(a.genericCoverages[0].coverageName) - VEHICLE_COVERAGE_SORT_CONSTANTS.indexOf(b.genericCoverages[0].coverageName)
  )

  // loop through genericCoverageGroups and build the CoverageDetails array
  return genericCoverageGroups.map(gcg => {
    const genericCoverage = gcg.genericCoverages[0]
    const coverageName = determineCoverageName(genericCoverage.coverageName)
    let coveragePropValue = ''
    let coveragePropType = ''

    if (!genericCoverage.coveragePurchasedSwitch) {
      coveragePropValue = 'No coverage'
    } else if (['EmergencyRoadService', 'RentalAndTravelExpense'].includes(genericCoverage.coverageName)) {
      coveragePropValue = 'Included'
    } else if (genericCoverage.genericCoveragePropertys && genericCoverage.genericCoveragePropertys.length > 0) {
      const codedValue = genericCoverage.genericCoveragePropertys.map(prop => prop.coveragePropertyValueNumeric).join('/')
      coveragePropValue = convertCodeToCurrency(codedValue)
      coveragePropType = determineSubText(genericCoverage.coverageName)
    }

    return { coverageName, coveragePropValue, coveragePropType }
  })
}

function getNotifications(messages: Message[]): Notification[] {
  return messages.filter(m => m.customerMessageEntityRelationshipName === 'vehicles').map(messageToNotification)
}

function getFormattedPremiumText(premiumAmount: number, duration: string): string {
  return toCurrency(premiumAmount) + `${duration !== 'other' ? `${'/' + getPolicyTermDuration(duration)}` : ''}`
}

function getVehicleStatus(vehiclePartyRoles: VehiclePartyRole[]): string {
  const role = vehiclePartyRoles.find(v => v.roleNameText?.length > 0)
  if (role && role.roleNameText === 'Lienholder') {
    if (role.thirdParty) {
      return 'Lien through ' + startCase(role.thirdParty.partyName.organizationName)
    }
    return 'Financed'
  }
  if (role && role.roleNameText === 'Lessor') {
    if (role.thirdParty) {
      return 'Lease through ' + startCase(role.thirdParty.partyName.organizationName)
    }
    return 'Leased'
  }
  return 'Owned'
}

function getExcludedCoverages(policyGenericCoverageSet: PolicyGenericCoverageSet): string[] {
  const coverageExclusions: string[] = []
  if (policyGenericCoverageSet && policyGenericCoverageSet.genericCoverageGroups.length > 0) {
    policyGenericCoverageSet.genericCoverageGroups.forEach(gcg => {
      let excludedCoverageName = ''
      gcg.genericCoverages.forEach(gc => {
        if (gc.coverageSuspendedSwitch) {
          excludedCoverageName = excludedCoverageName.concat(gc.coverageName)
        }
      })
      if (excludedCoverageName.length > 1) {
        coverageExclusions.push(determineCoverageName(excludedCoverageName))
      }
    })
  }
  return coverageExclusions
}

function determineSubText(codedText: string): string {
  const wording = VEHICLE_COVERAGE_SUBTEXT.find(subtext => subtext.coverage === codedText)
  return wording.subtext
}

export function determineCoverageName(codedText: string): string {
  const wording = VEHICLE_COVERAGE_NAMES.find(label => label.coverage === codedText)
  if (!wording) {
    throw `Missed Coverage in Auto  VEHICLE_COVERAGE_NAMES-${codedText}`
  }
  return wording.label
}

function convertCodeToCurrency(codedCurrency: string): string {
  const codes = codedCurrency.split('/')
  return codes.map(code => transformCurrency(Number(code))).join(' / ')
}

function transformCurrency(amount: number) {
  return toCurrency(amount, true)
}
// This is to test non exported functions
export let testNonExportedFunctions = {
  getFormattedPremiumText
}
